import Vue from 'vue'
import VueI18n from 'vue-i18n'
import sl from './locales/sl.json'
import en from './locales/en.json'

const messages = {
  en,
  sl
}

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'sl', // set default locale
  messages,
  availableLocales: ['sl', 'en']
})
